import React from "react";
import { APPOINTMENT_URL } from "./url/url";

export default function Appointment() {
  return (
    <div className="Services">
      <section className="page-section bg-light">
        <iframe
          title="Appointment Page"
          src={APPOINTMENT_URL}
          style={{ width: "100vw", height: "1000px", border: "none" }}
        />
      </section>
    </div>
  );
}
