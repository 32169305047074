import React, { Component } from "react";
import hairCut from "./images/men.jpg";
import beardTrim from "./images/beardTrim.jpg";
import childHairCut from "./images/kids.jpg";
import womenHairCut from "./images/women.jpg";
import studentHairCut from "./images/student.jpg";
import './index.css'
import { withTranslation } from "react-i18next";

class Price extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="Services">
        <section className="page-section bg-light" id="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="section-heading text-uppercase">{t("priceList.title")}</h2>
                <h3 className="section-subheading text-muted">
                {t("priceList.info")}
                </h3>
              </div>
            </div>
            <div className="row text-center">
              <div class="row">
                <div class="col-lg-4 col-md-6 mb-4">
                  <div class="card h-100">
                    <a href="/">
                      <img
                        class="card-img-top"
                        src={hairCut}
                        alt="menhaircut"
                      />
                    </a>
                    <div class="card-body">
                      <h4 class="card-title">
                        <a href="/"> {t("priceList.men")}</a>
                      </h4>
                      <h5>18€</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                  <div class="card h-100">
                    <a href="/">
                      <img
                        class="card-img-top"
                        src={womenHairCut}
                        alt="womenHairCut"
                      />
                    </a>
                    <div class="card-body">
                      <h4 class="card-title">
                        <a href="/"> {t("priceList.women")}</a>
                      </h4>
                      <h5>25€</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                  <div class="card h-100">
                    <a href="/">
                      <img
                        class="card-img-top"
                        src={childHairCut}
                        alt="childHairCut"
                      />
                    </a>
                    <div class="card-body">
                      <h4 class="card-title">
                        <a href="/"> {t("priceList.children")}</a>
                      </h4>
                      <h5>15€</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                  <div class="card h-100">
                    <a href="/">
                      <img
                        class="card-img-top"
                        src={beardTrim}
                        alt="beardTrim"
                      />
                    </a>
                    <div class="card-body">
                      <h4 class="card-title">
                        <a href="/"> {t("priceList.beard")}</a>
                      </h4>
                      <h5>12€</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                  <div class="card h-100">
                    <a href="/">
                      <img
                        class="card-img-top"
                        src={studentHairCut}
                        alt="studentHairCut"
                      />
                    </a>
                    <div class="card-body">
                      <h4 class="card-title">
                        <a href="/"> {t("priceList.student")}</a>
                      </h4>
                      <h5>15€</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default withTranslation("global")(Price);
