import React, {Component} from "react";
import aliRezaEbrahimi from "./images/a4small.jpg";
import { withTranslation } from "react-i18next";

class About extends Component {
  render() {
    const { t } = this.props; 
  return (
    <div id="about">
      <section className="page-section bg-light" id="team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase">  {t("aboutUs.title")}</h2>
              <div className="row text-center">
                <div class="row justify-content-center " >
                  <div class="col-lg-4 col-md-6 mb-4">
                    <div class="card h-100">
                      <a href="/appointment">
                        <img
                          class="card-img-top"
                          src={aliRezaEbrahimi}
                          alt="childHairCut"
                        />
                      </a>
                    
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="section-subheading text-muted"> {t("aboutUs.info")} </h3>
            </div>
          </div>
        </div>
      </section>
      
    </div>
  );}
}

export default withTranslation("global")(About);