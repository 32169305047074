import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { INST_URL, FB_URL, TIKTOK_URL, MAP_FRAME_URL } from "./url/url";
import "./contact.css";

export class Contact extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="Services">
        <section
          className="page-section"
          style={{ padding: "20px" }}
          id="services"
        >
          <div className="container">
            <div className="row">
              <div className="container py-md-5">
                <div className="text-center px-lg-5">
                  <h3 className="section-heading text-uppercase">
                    {t("contact.title")}
                  </h3>
                  <div className="title-desc text-center px-lg-5">
                    <p className="px-lg-5 sub-wthree">{t("contact.info")}</p>
                    <div className="mailAndNumber"><i className="fa-solid fa-at"></i> <p className="mailNumberText">alireza.parturi@gmail.com</p></div>
<div className="mailAndNumber"><i className="fa-solid fa-mobile-screen-button"></i> <p className="mailNumberText">0451516598</p></div>

                    <p className="px-lg-5 sub-wthree">{t("contact.message")}</p>
                  </div>
                  <span />
                  <div className="socialSpace">
                    <p>{t("contact.visit")}</p>

                    <div className="socialButton">
                      <a href={FB_URL}>
                        <i className="fab fa-facebook-f"></i>
                        <p>&nbsp;Facebook&nbsp;</p>
                      </a>
                    </div>
                    <div className="socialButton">
                      <a href={INST_URL}>
                        <i className="fab fa-instagram"></i>
                        <p>Instagram</p>
                      </a>
                    </div>
                    <div className="socialButton">
                      <a href={TIKTOK_URL}>
                        <i className="fa-brands fa-tiktok"></i>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;Tiktok&nbsp;&nbsp;&nbsp;&nbsp;</p>
                      </a>
                    </div>
                  </div>
                  <p class="px-lg-5 sub-wthree font-weight-bold">
                    Antinkatu 13B, 28100 Pori, Finland
                  </p>
                  <iframe
                    title="map-kkn-pori"
                    src={MAP_FRAME_URL}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    width="100%"
                    height="50%"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <span className="copyright">
                    Copyright &copy; Parturi KKN Pori 2024
                  </span>
                </div>
                <div className="col-md-4">
                  <ul className="list-inline social-buttons">
                    <li className="list-inline-item">
                      <a href={FB_URL}>
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={INST_URL}>
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={TIKTOK_URL}>
                        <i class="fa-brands fa-tiktok"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <ul className="list-inline quicklinks">
                    <li className="list-inline-item">
                      <a href="https://nosweatsuperdry.onrender.com/">
                        {t("designer.info")}
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://nosweatsuperdry.onrender.com/">
                        noSweatSuperDry
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </section>
      </div>
    );
  }
}

export default withTranslation("global")(Contact);
