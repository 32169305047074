import React, { Component } from "react";
import "./App.css";
import Home from "./Home";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Price from "./Price";
import Appointment from "./Appointment";
import About from "./About";
import { withTranslation } from "react-i18next";
import { MAP_URL } from "./url/url";

class App extends Component {
  render() {
    const { t } = this.props;
    const checkCurrennt = this.props.i18n.language;
    const handleChangeLanguage = (language) => {
      this.props.i18n.changeLanguage(language);
    };
    return (
      <Router>
        <div className="App">
          <nav>
            <nav
              className="navbar navbar-expand-lg navbar-dark fixed-top"
              id="mainNav"
            >
              <div className="container">
                <div className="col">
                  <a
                    className="navbar-brand text-uppercase js-scroll-trigger"
                    href="/"
                  >
                    Parturi KKN Pori
                  </a>
                  <a href={MAP_URL} className="nav-link text-white roboto row ">
                    Antinkatu 13B, 28100 Pori
                  </a>
                </div>
                <button
                  className="navbar-toggler navbar-toggler-right"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarResponsive"
                  aria-controls="navbarResponsive"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  {t("menu.menuTitle")}
                  <i className="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                  <ul className="navbar-nav text-uppercase ml-auto">
                    <li className="nav-item">
                      <a className="nav-link js-scroll-trigger" href="/">
                        <Link className="nav-link" to="/">
                          {t("menu.home")}
                        </Link>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link js-scroll-trigger"
                        href="/appointment"
                      >
                        <Link className="nav-link" to="/appointment">
                          {t("menu.appointment")}
                        </Link>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link js-scroll-trigger" href="/price">
                        <Link className="nav-link" to="/price">
                          {t("menu.priceList")}
                        </Link>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link js-scroll-trigger" href="/about">
                        <Link className="nav-link" to="/about">
                          {t("menu.about")}
                        </Link>
                      </a>
                    </li>
                    {checkCurrennt === "en" ? (
                      <li className="nav-item">
                        <a
                          href="/"
                          className="nav-link js-scroll-trigger"
                          onClick={() => handleChangeLanguage("fi")}
                        >
                          <Link className="nav-link">Suomen kielellä</Link>
                        </a>
                      </li>
                    ) : (
                      <div> </div>
                    )}
                    {checkCurrennt === "fi" ? (
                      <li className="nav-item">
                        <a
                        href="/"
                          className="nav-link js-scroll-trigger"
                          onClick={() => handleChangeLanguage("en")}
                        >
                          <Link className="nav-link">In English</Link>
                        </a>
                      </li>
                    ) : (
                      <div> </div>
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          </nav>
          <Routes>
          <Route path="/" element={<Home />} />
            <Route path="/appointment" element={<Appointment />} />
            <Route path="/price" element={<Price />} />
            <Route path="/about" element={<About />} />
          </Routes>
          
        </div>
      </Router>
    );
  }
}

export default withTranslation("global")(App);
