import React, { Component } from "react";
import { InstagramEmbed } from "react-social-media-embed";
import { INST_URL } from "./url/url";
import { withTranslation } from "react-i18next";

class Gallery extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="Services">  
     <section className="page-section" style={{ padding: '20px' }} id="services">

      <div class="container">
        <div class="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase"> {t("gallery.title")}</h2>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <InstagramEmbed url={INST_URL} width="100vw" />
            </div>
          </div>
        </div>
      </div>
      </section>
      </div>
    );
  }
}

export default withTranslation("global")(Gallery);
