import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import i18next from "i18next";
import global_en from "./translations/en/global.json";
import global_fi from "./translations/fi/global.json";
import { I18nextProvider } from "react-i18next";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "fi", // language to use
  resources: {
    en: {
      global: global_en, // 'common' is our custom namespace
    },
    fi: {
      global: global_fi,
    },
  },
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>,
  document.getElementById("root")
);

serviceWorker.register();
