import React, { Component } from "react";
import { withTranslation } from "react-i18next"; // Import withTranslation HOC
import Services from "./Services";
import "./Home.css";
import About from "./About";
import Price from "./Price";
import { APPOINTMENT_URL } from "./url/url";
import Gallery from "./Gallery";
import Contact from "./contact";

class Home extends Component {
  render() {
    const { t } = this.props; // Access translation function through props
    return (
      <div>
        <header className="masthead">
          <div className="container">
            <div className="intro-text img/header-bg.jpg">
              <div className="intro-lead-in text-uppercase">
                {t("home.message")}
              </div>
              <div className="intro-lead-in ">
              {t("home.body")}
              </div>
              <div className="intro-heading text-uppercase">0451516598</div>
              <a
                className="btn btn-primary btn-xl text-uppercase js-scroll-trigger"
                href={APPOINTMENT_URL}
              >
                {t("home.button")} {/* Translate button text */}
              </a>
              {/* <a className="btn btn-primary btn-xl text-uppercase js-scroll-trigger" href="#about">Book an appointment</a> */}
            </div>
          </div>
        </header>
        <Services />
        <Price />
        <Gallery />
        <About />
        <Contact />
      </div>
    );
  }
}

export default withTranslation("global")(Home); // Wrap Home component with withTranslation HOC and pass "global" namespace
