import React, { Component } from 'react';
import { withTranslation } from "react-i18next"; 


class Services extends Component {
    render() {
      const { t } =  this.props;
        return (
            <div className="Services">
    <section className="page-section" id="services">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h2 className="section-heading text-uppercase">{t("services.title")}</h2>
          <h3 className="section-subheading text-muted">{t("services.info")}</h3>
        </div>
      </div>
      <div className="row justify-content-center ">
        <div className="col-md-4">
          <span className="fa-stack fa-4x">
            <i className="fas fa-circle fa-stack-2x text-primary"></i>
            <i className="fas fa-cut fa-stack-1x fa-inverse"></i>
          </span>
          <h4 className="service-heading">{t("services.haircutText")}</h4>
          <p className="text-muted">{t("services.haircutTextDetails")}</p>
        </div>
      
        <div className="col-md-4">
          <span className="fa-stack fa-4x">
            <i className="fas fa-circle fa-stack-2x text-primary"></i>
            <i className="fab fa-confluence fa-stack-1x fa-inverse"></i>
          </span>
          <h4 className="service-heading">{t("services.hairStylingText")}</h4>
          <p className="text-muted">{t("services.hairStylingTextDetails")}</p>
        </div>
        <div className="col-md-4">
          <span className="fa-stack fa-4x">
            <i className="fas fa-circle fa-stack-2x text-primary"></i>
            <i className="fab fa-jenkins fa-stack-1x fa-inverse"></i>
          </span>
          <h4 className="service-heading">{t("services.beardText")}</h4>
          <p className="text-muted">{t("services.beardTextDetails")}</p>
        </div>
      </div>
    </div>
  </section>
            </div>
        );
    }
}


export default withTranslation("global")(Services);